// InfoCard.tsx
import React from 'react';
import { Card, Icon, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import '../styles/infocard.css';

interface InfoCardProps {
    header: number | string;
    meta: string;
    description: string | number;
    icon: SemanticICONS;
    trend?: number;
    trendColor?: string;
}

const InfoCard: React.FC<InfoCardProps> = ({ header, meta, description, icon, trend, trendColor = 'red' }) => {
    return (
        <Card className="info-card" fluid>
            <Card.Content>
                <Card.Header className="info-card-header">
                    {header} <Icon name={icon} className="info-card-icon" />
                </Card.Header>
                <Card.Meta className="info-card-meta">{meta}</Card.Meta>
                <Card.Description className="info-card-description">
                    {description}{trend !== undefined && (
                        <>
                            {' '}
                            <Icon name="arrow up" color={trendColor as SemanticCOLORS} />
                            {trend}%
                        </>
                    )}
                </Card.Description>
            </Card.Content>
        </Card>
    );
};

export default InfoCard;