import Cookies from "js-cookie";
import { ResponseGreenhouse } from "../pages/overview/models/ResponseGreenhouse";
import { ResponseGreenhouseOverview } from "../pages/overview/models/ResponseOverviewGreenhouse";
import { ImagesResponse } from "../pages/overview/models/ImageResponse";

const apiUrl = process.env.REACT_APP_API_URL;
const apiCode = process.env.REACT_APP_API_CODE;

export async function fetchGreenhouseData(greenhouseId: string): Promise<ResponseGreenhouse | undefined> {
    try {
        // Retrieve the token from cookies
        const token = Cookies.get('auth_token');

        const response = await fetch(`${apiUrl}/api/?greenhouseId=${greenhouseId}&code=${apiCode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data: ResponseGreenhouse = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to fetch greenhouse data:", error);
        return undefined;
    }
}

export async function fetchGreenhouseOverview(): Promise<ResponseGreenhouseOverview | undefined> {
    try {
        // Retrieve the token from cookies
        const token = Cookies.get('auth_token');

        const response = await fetch(`${apiUrl}/api/greenhouse?code=${apiCode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data: ResponseGreenhouseOverview = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to fetch greenhouse overview data:", error);
        return undefined;
    }
}

export async function fetchGreenhouseImages(greenhouseId: string): Promise<ImagesResponse | undefined> {
    try {
        // Retrieve the token from cookies
        const token = Cookies.get('auth_token');

        const response = await fetch(`${apiUrl}/api/image?greenhouseId=${greenhouseId}&code=${apiCode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data: ImagesResponse = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to fetch greenhouse data:", error);
        return undefined;
    }
}