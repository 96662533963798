import React, { useState } from 'react';
import { Button, Form, Grid, Input } from 'semantic-ui-react';
import { Recipe } from './recipe';

interface RecipeFormProps {
  onSave: (recipe: Recipe) => void;
  initialData?: Recipe;
}

const RecipeForm: React.FC<RecipeFormProps> = ({ onSave }) => {
  const [recipe, setRecipe] = useState<Recipe>({
    recipeName: '',
    recipeDescription: '',
    dayStartHour: 6,
    dayEndHour: 18,
    primaryLightStart: 6,
    primaryLightEnd: 18,
    secondaryLightStart: 0,
    secondaryLightEnd: 0,
    temperatureSetPointDay: 24,
    temperatureSetPointNight: 18,
    maxTemperatureDay: 28,
    maxTemperatureNight: 20,
    minTemperatureDay: 20,
    minTemperatureNight: 15,
    co2SetPoint: 800,
    maxCo2: 1000,
    minCo2: 400,
    humiditySetPoint: 60,
    maxHumidity: 70,
    minHumidity: 50,
    phSetPoint: 6.5,
    phMaxValue: 7.0,
    phMinValue: 5.5,
    ecSetPoint: 2.0,
    ecMaxValue: 2.5,
    ecMinValue: 1.5,
    section1LeftMlPerHour: 5,
    section2LeftMlPerHour: 5,
    section3LeftMlPerHour: 5,
    section4LeftMlPerHour: 5,
    section5LeftMlPerHour: 5,
    section6LeftMlPerHour: 5,
    section7LeftMlPerHour: 5,
    section1RightMlPerHour: 5,
    section2RightMlPerHour: 5,
    section3RightMlPerHour: 5,
    section4RightMlPerHour: 5,
    section5RightMlPerHour: 5,
    section6RightMlPerHour: 5,
    section7RightMlPerHour: 5,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRecipe((prevRecipe) => ({
      ...prevRecipe,
      [name]: parseFloat(value) || value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(recipe);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Field>
        <label>Recipe Name</label>
        <Input
          fluid
          placeholder="Enter recipe name"
          name="recipeName"
          value={recipe.recipeName}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>Description</label>
        <Input
          fluid
          placeholder="Enter description"
          name="recipeDescription"
          value={recipe.recipeDescription}
          onChange={handleChange}
        />
      </Form.Field>

      <h3>Day settings</h3>
      <Form.Group widths="equal">
        <Form.Input
          type="number"
          label="Day Start Hour"
          name="dayStartHour"
          value={recipe.dayStartHour}
          onChange={handleChange}
          min="0"
          max="23"
        />
        <Form.Input
          type="number"
          label="Day End Hour"
          name="dayEndHour"
          value={recipe.dayEndHour}
          onChange={handleChange}
          min="0"
          max="23"
        />
      </Form.Group>

      <h3>Lighting Settings</h3>
      <Form.Group widths="equal">
        <Form.Input
          type="number"
          label="Primary Light Start"
          name="primaryLightStart"
          value={recipe.primaryLightStart}
          onChange={handleChange}
          min="0"
          max="23"
        />
        <Form.Input
          type="number"
          label="Primary Light End"
          name="primaryLightEnd"
          value={recipe.primaryLightEnd}
          onChange={handleChange}
          min="0"
          max="23"
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          type="number"
          label="Secondary Light Start"
          name="secondaryLightStart"
          value={recipe.secondaryLightStart}
          onChange={handleChange}
          min="0"
          max="23"
        />
        <Form.Input
          type="number"
          label="Secondary Light End"
          name="secondaryLightEnd"
          value={recipe.secondaryLightEnd}
          onChange={handleChange}
          min="0"
          max="23"
        />
      </Form.Group>


      <h3>Temperature Settings</h3>
      <Form.Group widths="equal">
        <Form.Input
          type="number"
          label="Day Temperature Set Point"
          name="temperatureSetPointDay"
          value={recipe.temperatureSetPointDay}
          onChange={handleChange}
        />
        <Form.Input
          type="number"
          label="Night Temperature Set Point"
          name="temperatureSetPointNight"
          value={recipe.temperatureSetPointNight}
          onChange={handleChange}
        />
      </Form.Group>

      <h3>CO₂ and Humidity Settings</h3>
      <Form.Group widths="equal">
        <Form.Input
          type="number"
          label="CO2 Set Point (ppm)"
          name="co2SetPoint"
          value={recipe.co2SetPoint}
          onChange={handleChange}
        />
        <Form.Input
          type="number"
          label="Max CO2 (ppm)"
          name="maxCo2"
          value={recipe.maxCo2}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          type="number"
          label="Humidity Set Point (%)"
          name="humiditySetPoint"
          value={recipe.humiditySetPoint}
          onChange={handleChange}
        />
        <Form.Input
          type="number"
          label="Max Humidity (%)"
          name="maxHumidity"
          value={recipe.maxHumidity}
          onChange={handleChange}
        />
      </Form.Group>

      <h3>pH and EC Settings</h3>
      <Form.Group widths="equal">
        <Form.Input
          type="number"
          label="pH Set Point"
          name="phSetPoint"
          value={recipe.phSetPoint}
          onChange={handleChange}
        />
        <Form.Input
          type="number"
          label="Max pH"
          name="phMaxValue"
          value={recipe.phMaxValue}
          onChange={handleChange}
        />
      </Form.Group>

      <h3>Water Flow Rates (ml/hr)</h3>
      {[...Array(7)].map((_, i) => (
        <Form.Group widths="equal" key={i}>
          <Form.Input
            type="number"
            label={`Section ${i + 1} Left`}
            name={`section${i + 1}LeftMlPerHour`}
            value={(recipe as any)[`section${i + 1}LeftMlPerHour`]}
            onChange={handleChange}
          />
          <Form.Input
            type="number"
            label={`Section ${i + 1} Right`}
            name={`section${i + 1}RightMlPerHour`}
            value={(recipe as any)[`section${i + 1}RightMlPerHour`]}
            onChange={handleChange}
          />
        </Form.Group>
      ))}

      <Button type="submit" color="blue">Save Recipe</Button>
    </Form>
  );
};

export default RecipeForm;