import React, { useState, useEffect } from 'react';
import { Grid, Image, Dropdown } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import Sidemenu from './sidemenu';
import { Outlet } from 'react-router-dom';

export default function Home() {
    const [openDropdown, setOpenDropdown] = useState(false);
    const navigate = useNavigate();
    const [username, setUsername] = useState<string | null>(null);

    const handleLogout = () => {
        Cookies.remove('auth_token');
        navigate('/login');
    };

    useEffect(() => {
        const token = Cookies.get('auth_token');
        if (token) {
            try {
                const decoded = jwtDecode<JwtPayload>(token);
                setUsername(decoded.sub ?? null);
            } catch (error) {
                Cookies.remove('auth_token');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }, [navigate]);

    const dropdownOptions = [
        { key: 'profile', text: 'Profile', icon: 'user', onClick: () => navigate('/profile') },
        { key: 'logout', text: 'Log Out', icon: 'sign out', onClick: handleLogout },
    ];

    return (
        <Grid>
            <Grid.Row className='TopRow'>
                <Grid.Column width={2}>
                    <Image
                        className='logo'
                        src='./elius_logo.png'
                        as='a'
                        size='small'
                        href='#'
                        target='_blank' />
                </Grid.Column>
                <Grid.Column width={14} className='NavBarColumnLogin' textAlign='right'>
                    <Dropdown
                        trigger={
                            <span>
                                <img src="https://react.semantic-ui.com/images/avatar/small/elliot.jpg" className='avatar' alt="avatar" />
                                <div style={{ marginTop: '5px', fontWeight: 'bold' }}>{username}</div>
                            </span>
                        }
                        pointing='top right'
                        icon={null}
                        open={openDropdown}
                        onClick={() => setOpenDropdown(!openDropdown)}
                        onBlur={() => setOpenDropdown(false)}
                        options={dropdownOptions}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className='ContentRow'>
                <Grid.Column className='SideBarColumn' width={3}>
                    <Sidemenu />
                </Grid.Column>
                <Grid.Column width={13} className='OutletColumn'>
                    <Outlet />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
