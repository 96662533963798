import React from 'react'; // get the React object from the react module
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
);

export default function eliuslinechart({ title, labels, data, minValue, maxValue, showlabels = true, showTitle = true, maxHeight = 600 }) {

    const colorPalette = ['rgba(0,80,115, 0.9)', 'rgba(16,125,172, 0.9)', 'rgba(24,154,211, 0.9)', 'rgba(30,187,215, 0.9)', 'rgba(113,199,236, 0.9)'];

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: showTitle,
            },
            title: {
                display: showTitle,
                text: title,
            },
            labels: {

            }
        },
        scales: {
            x: {
                ticks: { display: false }
            },
            y: {
                min: minValue,
                max: maxValue
            }
        }
    };

    function generateDatasets(data) {

        let datasets = [];

        let dataset = {
            label: title,
            data: data,
            backgroundColor: colorPalette[0]
        };

        datasets.push(dataset);

        return datasets;
    }

    const dataset = {
        labels: labels,
        datasets: generateDatasets(data)
    }

    return (
        <div className='ChartBackground' style={{height: maxHeight, marginRight: 10}}>
            <Line title={title} options={options} data={dataset} width={800}/>
        </div>
    )
}