import React from 'react';
import './styles/App.css';
import './styles/Visuals.css';
import './styles/charts.css'
import 'semantic-ui-css/semantic.min.css'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Cookies from 'js-cookie';

// Pages
import Home from './pages/layout/home';
import Login from './pages/user/Login'; // Import your login component

// General
import Overview from './pages/overview/overview';
import ContainerOverview from './pages/overview/containeroverview';
import Reports from './pages/reporting/reports';
import RecipeList from './pages/recipe/recipelist';
import Settings from './pages/settings/settings';

// Store
import Shop from './pages/shop/shop';
import Billing from './pages/billing/billing';

// Support
import ContactSupport from './pages/support/contactsupport';
import Faq from './pages/faq/faq';

// PrivateRoute
import PrivateRoute from './pages/user/PrivateRoute'; // Import PrivateRoute component
import Profile from './pages/user/Profile';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} /> {/* Login Page */}

        {/* General Routes */}
        <Route path="/" element={<Home />}>
          <Route path="/" element={<Navigate replace to="/overview" />} />

          {/* Protect all routes under PrivateRoute */}
          <Route element={<PrivateRoute />}>
            <Route path="overview" element={<Overview />} />
            <Route path="overview/:id" element={<ContainerOverview />} />
            <Route path="recipes" element={<RecipeList />} />
            <Route path="settings" element={<Settings />} />

            {/* Store */}
            <Route path="shop" element={<Shop />} />
            <Route path="billing" element={<Billing />} />

            {/* Support */}
            <Route path="contact" element={<ContactSupport />} />
            <Route path="faq" element={<Faq />} />

            {/* User */}
            <Route path="profile" element={<Profile />} />
          </Route>
        </Route>

        {/* Catch-all route to handle unrecognized URLs */}
        <Route path="*" element={<Navigate to="/" replace />} />

      </Routes>
    </BrowserRouter>
  );
}
