import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { Button, Form, Grid, Header, Segment, Message, Icon } from "semantic-ui-react";
import { jwtDecode } from "jwt-decode";
const apiUrl = process.env.REACT_APP_API_URL;
const apiCode = process.env.REACT_APP_API_CODE;

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (e: any) => {
    e.preventDefault();
    setLoading(true); // Show loading spinner

    try {
      // Hash the password with SHA-256
      const hashedPassword = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);

      // Make the API call
      const response = await axios.post(`${apiUrl}/api/login?code=${apiCode}`, {
        username: username,
        password: hashedPassword,
      });

      // If login is successful
      if (response.status === 200) {
        const { token } = response.data; // Extract userNumber and token from response

        // Decode the JWT token to extract the expiration time
        const decodedToken = jwtDecode(token);

        if (decodedToken.exp !== undefined) {
          const tokenExpiration = decodedToken.exp; // exp is in seconds since epoch

          // Calculate the expiration date for the cookie based on the JWT exp claim
          const expires = new Date(tokenExpiration * 1000); // Convert from seconds to milliseconds

          // Set the token cookie using the expiration from the JWT
          Cookies.set('auth_token', token, { expires: expires, secure: true, sameSite: 'Strict' });

          // Redirect to the Overview page
          navigate("/Home");
        }else{
          alert("Issue with sign in. Contact support@elius.dk")
        }
      } else {
        setError("Invalid username or password");
      }
    } catch (err) {
      setError("Error logging in. Please try again.");
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  return (
    <Grid
      textAlign="center"
      style={{ height: "100vh" }}
      verticalAlign="middle"
    >
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          <Icon name="lock" /> Log-in to your account
        </Header>
        <Form size="large" onSubmit={handleLogin} error={!!error}>
          <Segment stacked>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {error && <Message error content={error} />}

            <Button color="teal" fluid size="large" loading={loading} disabled={loading}>
              Login
            </Button>
          </Segment>
        </Form>
        {/* <Message>
          New to us? <a href="#">Sign Up</a>
        </Message> */}
      </Grid.Column>
    </Grid>
  );
};

export default Login;
