import React, { useState } from 'react';
import { Button, Card, Modal } from 'semantic-ui-react';
import RecipeForm from './recipeform';
import { Recipe } from './recipe';  // Adjust the import path to your types file

const RecipeList: React.FC = () => {
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [currentRecipe, setCurrentRecipe] = useState<Recipe | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleEdit = (recipe: Recipe) => {
    setCurrentRecipe(recipe);
    setModalOpen(true);
  };

  const handleDelete = (id?: string) => {
    if (id) {
      setRecipes(recipes.filter(recipe => recipe.recipeId !== id));
    }
  };

  const handleSave = (recipe: Recipe) => {
    if (recipe.recipeId) {
      setRecipes(recipes.map(r => (r.recipeId === recipe.recipeId ? recipe : r)));
    } else {
      const newRecipe = { ...recipe, id: recipes.length + 1 };  // Assign an ID for new recipes
      setRecipes([...recipes, newRecipe]);
    }
    setModalOpen(false);
    setCurrentRecipe(null);
  };

  // const openModalToAdd = () => {
  //   setCurrentRecipe({
  //     recipeDescription: '',

  //     daytime: '',
  //     dayTemperature: 20,
  //     dayHumidity: 50,
  //     nightTemperature: 15,
  //     nightHumidity: 60,
  //     co2: 400,
  //     ph: 7.0,
  //     ec: 1.0
  //   });
  //   setModalOpen(true);
  // };

  return (
    <div style={{ marginTop: 10 }}>
      <Button onClick={() => alert("not implemented")}>Add New Recipe</Button>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        size="large"
        style={{ width: '80%' }}
      >
        <Modal.Header>{currentRecipe?.recipeId ? 'Edit Recipe' : 'Add New Recipe'}</Modal.Header>
        <Modal.Content>
          <RecipeForm onSave={handleSave} />
        </Modal.Content>
      </Modal>

      <div style={{ marginTop: 10 }}>
        <Card.Group>
          {recipes.map(recipe => (
            <Card key={recipe.recipeId}>
              <Card.Content>
                <Card.Header>{recipe.recipeName}</Card.Header>
                <Card.Meta>{recipe.dayStartHour}</Card.Meta>
                <Card.Description>
                  Temperature: {recipe.temperatureSetPointDay}°C, Humidity: {recipe.humiditySetPoint}%, CO2: {recipe.co2SetPoint}ppm, pH: {recipe.phSetPoint}, EC: {recipe.ecSetPoint}
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <div className="ui two buttons">
                  <Button basic color="green" onClick={() => handleEdit(recipe)}>
                    Edit
                  </Button>
                  <Button basic color="red" onClick={() => handleDelete(recipe.recipeId)}>
                    Delete
                  </Button>
                </div>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      </div>
    </div>
  );
}

export default RecipeList;
